/* 
.MainCalendar .Cal__Today__chevron {
  position: absolute;
  left: 220px;
} */

.MainCalendar .Cal__Container__root {
    width: 100% !important;
}
  
.MainCalendar .Cal__Day__root {
    border: 1px solid rgba(0,0,0,.05);
}

.MainCalendar .Cal__Day__month {
    font-size: 10
}
  
.MainCalendar .header-icons {
    position:absolute;
    top:0px;
    right:0px;
    z-index: 1000;
    padding:20px;
}

.MainCalendar .header-icons svg {
    color: white;
    cursor: pointer;
    zoom:1.25;
    margin: 0px 5px;
}