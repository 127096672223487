.login-dialog input {
    max-width:155px;
    font-size:30px;
  }
  
.login-dialog div.unlock-status {
    margin-top: 14px;
    text-align:center;
}

.login-dialog div.unlock-status svg {
    font-size: 31px;
}

.login-dialog div.unlock-status svg[data-icon="lock"] {
    opacity:.75;
    color:goldenrod;
    fill: none;
}

.login-dialog div.unlock-status svg[data-icon="times"] {
    opacity:.75;
    color:red;
    fill: none;
}
